@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600&display=swap');

body {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
	font-family: 'Josefin Sans', sans-serif !important;
	color: #1D1B30;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
	font-family: 'Josefin Sans', sans-serif !important;
}

.MuiFormLabel-root,
.MuiInputBase-root,
.MuiButtonBase-root,
.MuiTypography-root,
input {
	font-family: 'Josefin Sans', sans-serif !important;
}

p {
	padding: 0;
	margin: 0;
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

a {
	text-decoration: none;
}

a.fancy {
	color: inherit;
	text-decoration: none;
	display: inline-block;
	position: relative;
	text-shadow: 1px 1px 0 #e5e6e6, -1px 1px 0 #e5e6e6, 1px -1px 0 #e5e6e6, -1px -1px 0 #e5e6e6;
}

a.fancy:before,
a.fancy:after {
	content: "";
	background: #000;
	position: absolute;
	bottom: 0.1em;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: -1;
}

a.fancy:before {
	left: 50%;
	top: 50%;
	height: 0;
	background: rgba(0, 174, 154, 0.1);
	transform: translate(-50%, -50%);
}

a.fancy:hover:after {
	background: #00ae9a;
}

a.fancy:hover:before {
	animation-duration: 0.3s;
	animation-name: balloon;
	animation-fill-mode: forwards;
}

@keyframes balloon {
	from {
		width: 0.5em;
		height: 0.5em;
		border-radius: 50%;
	}

	to {
		width: calc(100% + 0.5em);
		height: calc(100% + 0.5em);
		border-radius: 0;
	}
}

.MuiToolbar-root.MuiToolbar-regular {
	padding: 0px 20px;
}

.full-h {
	height: 100%;
}

.plain-app {
	height: 100vh;
}


.filter-bar {
	margin-bottom: 20px !important;
}

.chit-boxes {
	padding: 20px;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.chit-boxes>h2 {
	text-transform: capitalize;
}

.chit-boxes>h2>a {
	float: right;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 400;
}

.chit-boxes>h4 {
	font-size: 16px;
	margin-bottom: 30px;
	position: relative;
}

.chit-boxes>h4:before {
	position: absolute;
	content: "";
	left: 0;
	bottom: -10px;
	width: 60px;
	height: 3px;
	background-image: linear-gradient(to right, #25aae1, #40e495);
}

.chit-boxes h4 span {
	font-size: 12px;
	color: #9d9d9d;
	font-weight: 300;
}

.span-grey {
	font-size: 12px;
	color: #9d9d9d;
	font-weight: 300;
}

.chit-boxes .ul-flex {
	display: flex;
	margin-bottom: 15px;
}

.chit-boxes .ul-flex li {
	margin-right: 30px;
}

.chit-boxes .ul-flex li span {
	font-size: 14px;
	color: #9d9d9d;
	font-weight: 300;
}

.chit-boxes .MuiDivider-wrapper {
	font-size: 12px;
	text-transform: uppercase;
	color: #9d9d9d;
}

.chit-boxes .chit-collector {
	display: flex;
	padding-top: 10px;
}

.chit-boxes .chit-collector div {
	padding: 0px 10px;
}

.chit-boxes .chit-collector div h4 {
	font-size: 13px;
}

.chit-boxes .chit-collector div p {
	font-size: 12px;
}

.chit-boxes.chit-nfo {
	padding: 0 !important;
	box-shadow: none;
}


.info-list article.article-header {
	padding: 5px 10px;
	border-radius: 4px;
	margin-bottom: 2px;
	background-color: #e5e5e5;
}

.info-list article.article-header label {
	font-size: 10px;
	font-weight: 400;
	text-transform: uppercase;
}

.info-list article.article-content {
	padding: 10px;
	border-radius: 4px;
	margin-bottom: 2px;
	background-color: #e5e5e5;
}

.info-list article.article-content.verified {
	background-color: #daf0e0;
}

.info-list article.article-content.verified i {
	color: #2f8547;
}

.info-list article.article-content.new {
	background-color: #dee4fb;
}

.info-list article.article-content.new i {
	color: #3d55b2;
}

.info-list article.article-content.in-active {
	background-color: #ffe0e0;
}

.info-list article.article-content.in-active i {
	color: #d04b4b;
}

.info-list article.article-content h3 {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.info-list article.article-content h4 {
	font-size: 13px;
	font-weight: 400;
	color: #000;
}

.info-list .txt-right label {
	display: block;
	text-align: right;
}

.profile-input .MuiFormControl-root {
	width: 100%;
	margin-bottom: 10px;
}

.passbook-page .pass-header {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600;
	padding: 5px 5px;
	background-color: #eee;
}

.pass-header-alt {
	font-size: 12px;
	color: #666;
	font-weight: 500;
	padding: 5px 5px;
	border-bottom: 1px solid #eee;
	transition: all 0.3s ease-in-out;
}

.pass-header-alt.active {
	background-color: #f7f7f7;
	color: #000;
}

.pass-header-alt>span {
	color: #bdbcbc;
}