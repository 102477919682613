@media only screen and (max-width:767px) {
    .login-form {
        width: 90% !important;
    }
}

@media only screen and (min-width:768px) and (max-width:959px) {
    .login-form {
        width: 60% !important;
    }
}